import en from "@/plugins/vee-validate/EnValidateMessage.json";

export default {
	notification: {
		error: "Error",
		warning: "Warning",
		success: "Successful",
	},
	validation: en.messages,

	methodsAuction: "Auction for reduction of the starting price",
	test: "TEST",
	tashkent: "Tashkent",
	contacts: "Contacts",
	address: "Address",
	workingHours: "Working hours",
	moreAboutServices: "More about services",
	submitApplication: "Leave a request",
	bannerText: "Comprehensive solutions for your business in the field of Digital",
	whoAreWe: "Who are we?",
	whyAreWe: "Why us?",
	services: "Everything for the development of your business",
	getAnOffer: "Read our commercial offer",
	partners: "We value the trust of our customers",
	technologies: "Technologies that we use",
	gallery: "Gallery",
	faq: "Questions",
	feedback: "Comments",
	company: "About Company",
	partnersMenu: "Partners",
	servicesMenu: "Services",
	vacancies: "Vacancies",
	copyrights: "All rights reserved.",
	copyrightsInfo: "No information on this website constitutes a public offer",
	yourName: "Your name",
	yourPhoneNumber: "Your number",
	yourEmail: "Your mail",
	captcha: "Enter text from image",
	getOffer: "Get a offer",
	error_404: "ERROR 404",
	errorTitle: "It may not be what you were looking for.",
	errorSubtitle:
		"Sorry, the page you requested does not exist. Please check the correct spelling of the address or go to the main page.",
	backToHomePage: "Back to home page",
	errorText: "Something get wrong",
	error: "Error",
	success: "Successful",
	messageSuccessfullySent: "Your message has been successfully sent.",
	homeMainBannerTitle: "Electronic Cooperation Portal of the Republic of Uzbekistan",
	quickRegistration: "Quick registration",
	startNow: "Start now",
	callCenter: "Call centre",
	signIn: "Log in",
	signUp: "Registration",
	logOut: "Exit",
	personalCabinet: "Personal cabinet",
	home: "Main",
	allSections: "All sections",
	procurement: "Purchases",
	planGraphic: "Schedule plan",
	shop: "E-catalog",
	auction: "Auction",
	selection: "Selection",
	tender: "Tender",
	directContracts: "Direct contracts",
	purchasingRange: "Purchasing range",
	priceCriteria: "Price criteria",
	calculator: "Calculator",
	information: "Information",
	statistics: "Statistics",
	standardFormsAndContracts: "Model forms and contracts",
	normativeActs: "Normative acts",
	complaintsCommissionDecisions: "Decisions of the Complaints Commission",
	instructions: "Instructions",
	catalog: "Catalog of goods",
	news: "News",
	allNews: "All news",
	tariffs: "Tariffs",
	subjectsOfProcurement: "Subjects of procurement",
	unifiedRegisterOfUnscrupulousPerformers: "Unified Register of Unfair Performers",
	singleSupplierRegister: "Register of single suppliers",
	registerOfCorporateCustomers: "Register of Corporate Customers",
	registryOfBudgetCustomers: "Register of budget customers",
	supplierRegister: "Supplier roster",
	specializedOrganizations: "Specialized organizations",
	operatorServices: "Operator services",
	videoInstruction: "Video instruction",
	purchasingMethods: "Methods of procurement",
	suppliers: "Suppliers",
	supplier: "Supplier",
	location: "Chilanzar-2, Arnasoy street,76",
	inn: "TIN",
	mfo: "Bank kode",
	checkingAccount: "Settlement account",
	aboutPortal: "About the portal",
	publicOffer: "Public offer",
	formOfDocuments: "Form of documents",
	technicalSupport: "Technical support",
	category: "Category",
	commissionAmount: "Commission amount",
	totalAmount: "Total amount",
	pledgeAmount: "Collateral amount",
	commissionFee: "Commission fee",
	totalBlockAmount: "Total amount blocked",
	summa: "Amount",
	pledge: "Deposit",
	total: "Total",
	days: "Days",
	hours: "Hours",
	minutes: "Minutes",
	seconds: "Seconds",
	orderGlass: "Order glass",
	startingAmount: "Starting amount",
	currentAmount: "Current amount",
	nextPrice: "Next price",
	apply: "Apply",
	biddingHistory: "Bidding history",
	price: "Price",
	decrease: "Decrease",
	date: "Date",
	time: "Time",
	name: "Name",
	amountShort: "Quantity",
	amount: "Quantity",
	minimumAmount: "Min. quantity",
	unitShort: "Unit of measure.",
	description: "Description",
	estimatedDeliveryTime: "Delivery date",
	lotInformation: "Lot information",
	customerInformation: "Customer information",
	filter: "Filter",
	search: "Search",
	lotNumber: "lot No.",
	region: "Region",
	chooseRegion: "Select a region",
	chooseCategory: "Select a category",
	clearFilter: "Clean the filter",
	cancel: "Cancel",
	show: "Showing",
	searchByTaxNumber: "Showing",
	organizationName: "Name of organization",
	procedureType: "Type of procedure",
	enterTheAmountOfTheCommercialOffer: "Enter the amount of the quotation",
	selectThePercentageOfTheCollateral: "Select the percentage of the collateral amount",
	collateralAmount: "Pledged amount",
	theAmountOfOperatorServices: "Amount of operator services",
	close: "Close",
	contest: "Competition",
	customer: "Customer",
	provider: "Supplier",
	contractNo: "contract No.",
	productName: "Product Name",
	customerName: "Name of the customer",
	workAndServices: "works, services",
	contractAmount: "Contract amount",
	PerformerName: "Name of executor",
	noData: "No data",
	totalStartingCost: "Total starting cost",
	calendarTerm: "calendar period",
	deliveryAddress: "Delivery address",
	contractNumber: "Contract number",
	contractDate: "Date of contract",
	productDetail: "Product characteristic",
	customerInn: "TIN of customer",
	customerRegion: "Customer region",
	customerDistrict: "Customer's district",
	customerAddress: "Customer's address",
	customersPersonalAccountWithTheTreasury: "Customer's personal account in the Treasury",
	perforemersRegion: "Performer's region",
	performersDistrict: "Performer's neighborhood",
	performersAddress: "Executor's address",
	performerInformation: "Executor's address",
	questionCategories: "Question categories",
	allSuppliers: "All suppliers",
	voted: "Voted",
	signedContracts: "Contracts concluded",
	totalDealAmount: "Total amount of deals (mln. UZS)",
	participants: "Participants",
	products: "Goods",
	perday: "Per day",
	usefulLinks: "Useful links",
	enterProductNumberOrName: "Enter the number or name of the product…",
	instructionSupplierRegistration: "Instructional provider registration",
	instructionSupplierAuction: "Vendor Store Instruction",
	instructionCustomerRegistration: "Instruction customer registration",
	instructionCustomerPlanGraphic: "Instruction customer registration",
	instructionCustomerAuction: "Instruction customer registration",
	instructionSupplierShop: "Supplier instructions for the store",

	instructionCustomerPlanSchedule: "Instruction to the customer plan schedule",
	instructionsCustomerAuction: "Instruction to the customer on the auction",
	instructionsCustomerShop: "Instruction to the customer on the store",
	instructionsCustomerRegistration: "Instructions to the customer for registration",
	instructionsCustomerBudget: "Instructions for obtaining a reference",
	instructionsCustomerBudgetAll:
		"Instruction on registration of budget organizations, creation of a schedule plan and procurement on the platform of the electronic cooperation portal",
	instructionService: "Instructions for posting works and services",
	suppliersInstructionsAuction: "Instructions to the supplier for the auction",
	suppliersInstructionsShop: "Supplier instructions for the store",
	suppliersInstructionsRegistration: "Vendor Registration Instructions",

	instructionCustomerPlanScheduleSize: "97 kWt",
	instructionsCustomerAuctionSize: "700 kWt",
	instructionsCustomerShopSize: "1720 kWt",
	instructionsCustomerRegistrationSize: "223 kWt",
	suppliersInstructionsAuctionSize: "1177 kWt",
	suppliersInstructionsShopSize: "1591 kWt",
	suppliersInstructionsRegistrationSize: "160 kWt",

	learnMore: "Learn More",
	latestNews: "Latest news",
	publishedDate: "Published",
	shareOnSocialMediaNetworks: "Share on social media",
	bargainingSubject: "Subject of bargaining",
	month: "Month",
	chooseYear: "Select a year",
	year: "Year",
	subjectOfTheAuction: "Subject of bidding",
	action: "Actions",
	quarter: "Quarter",
	view: "Preview",
	priceCriteriaForPublicProcurement: "Price criteria for public procurement",
	subjectOfProcurement: "Subject of procurement",
	budgetCustomers: "Budget customers",
	corporateCustomers: "Corporate customers",
	purchaseType: "Type of procurement",
	eShop: "E-catalog",
	product: "Product",
	noLimitSet: "No limit set",
	notLessThan5WorkingDays: "at least 5 working days",
	notLessThan30WorkingDays: "at least 30 working days",
	nameOfGoodsAllowedForPurchaseFromASingleSupplier:
		"Name of goods (works, services) permitted\n" + "\t\t\t\t\t\t\tfor public procurement from a single supplier",
	reason: "Basis",
	advancedSearch: "Advanced search",
	country: "Country",
	chooseCountry: "Select a country",
	manufacturer: "Manufacturer",
	unitPrice: "Price per unit",
	from: "From",
	until: "up to",
	more: "For further details",
	expirationDate: "End date",
	showLess: "Show less",
	showMore: "Show more",
	orderName: "Name of order",
	deadline: "End date",
	productQuantity: "Quantity of goods",
	showPeriod: "Show period",
	procurementInfoByRegion: "Information on procurement by region",
	transactionAmount: "Transaction amount",
	saving: "Economy",
	dealAmount: "Number of transactions",
	top5SoldProductCategories: "Top 5 marketable product categories",
	categoryName: "Name of category",
	selectionOfTheBestOffers: "Selection",
	collateral: "collateral",
	stateProcurements: "On public procurement",
	ResolutionsOfThePresident: "Resolutions of the President of Uzbekistan",
	LawsOfUzb: "Laws of the Republic of Uzbekistan",
	DecreesOfThePresident: "Decrees of the President of the Republic of Uzbekistan",
	provisions: "Provisions",
	docText4544:
		"Decree of the President of the Republic of Uzbekistan “On measures to further improve the system of public procurement and wide involvement of business entities in the process of public procurement” dated 05.12.2019. №4544.",
	docText3016:
		"Order of the National Project Management Agency under the President of the Republic of Uzbekistan “On Approval of the Regulations on the procedure for organizing and conducting procurement procedures” (Registered by the Ministry of Justice of the Republic of Uzbekistan on May 26, 2018. Registration No. 3016)",
	docText3015:
		"Order of the Director of the National Project Management Agency under the President of the Republic of Uzbekistan “On approval of the regulation on the activities of the operator of the special information portal in relation to the organization and conduct of public procurement” (Registered by the Ministry of Justice of the Republic of Uzbekistan on May 21, 2018. Registration No. 3015)",
	docText3013:
		"Order of the Director of the National Project Management Agency under the President of the Republic of Uzbekistan “Regulations on the procedure for consideration of complaints in the sphere of public procurement” (Registered by the Ministry of Justice of the Republic of Uzbekistan on May 14, 2018. Registration No. 3013)",
	docText3057:
		"Order of the National Project Management Agency under the President of the Republic of Uzbekistan “On Approval of the Regulations on specialized organizations entitled to provide services for public procurement” (Registered by the Ministry of Justice of the Republic of Uzbekistan on August 20, 2018. Registration No. 3057)",
	docText3953:
		"Decree of the President of the Republic of Uzbekistan “On measures to implement the Law of the Republic of Uzbekistan ‘On public procurement’ from 27.09.2018. №3953.",
	docTextAuctionContracts:
		"Model contract for the supply of goods based on the results of electronic public procurement on the platform “Starting Price Reduction Auction”",
	docTextShopContracts:
		"Model contract for the supply of goods based on the results of electronic public procurement on the “Electronic Catolog” platform",
	servicesText1:
		"Didox is the most accessible electronic invoice service. It is free for most businesses and the most profitable for everyone else.",
	servicesText2:
		"When you use Venkon's 1C Accounting software product, you can utilize data integration capabilities directly from your Accounting system.",
	servicesText3:
		"The service allows the bank's clients to perform banking operations directly from their accounting systems “1C” and “Didox”.",
	servicesText4: "We take care of reliable suppliers, quality goods and secure payment.",

	numberTrade: "Number of trades",
	tradingVolume: "Trading volume",
	signInOrder: "Log in to order",
	requestPrice: "Request a price",
	count: "Quantity",
	productDescription: "Product Description",
	minMaxParty: "Min/max party",
	tradingUntilActive: "Bargaining is active until",
	deliveryTime: "Delivery date",
	brand: "Brand",
	manufacturerCountry: "Country of origin",
	shelfLife: "Expiration date",
	warrantyPeriod: "Warranty period",
	warrantyTerms: "Warranty terms",
	productYear: "Year of production",
	techSpecifications: "Technical parameters",
	epidemiological: "Epidemiological reports",
	environmental: "Environmental expertise",
	autoRenewal: "Auto-renewal",
	conclusions: "Veterinary and sanitary reports",
	sertificate: "Certificate availability",
	phytosanitary: "Phytosanitary reports",
	license: "Availability of license",
	veterinary: "Veterinary reports",
	nationalManufacturer: "National manufacturer",
	deliveryRegion: "Delivery region",
	deliveryRegions: "Delivery regions",
	relativeProducts: "Similar Products",
	showAll: "Show all",
	request: "Request",
	protocolText01: "Protocol of approval of procurement documentation on selection",
	protocolText02: "Protocol for compliance verification",
	protocolText03: "Protocol for the resumption of selection",
	protocolText04: "Protocol for canceling the selection",
	protocolText05: "Protocol for determining the winner of the selection, point method",
	protocolText06: "Protocol for determining the winner of the selection, lowest price method",
	protocolText07: "Protocol of evaluation of the technical part of the selection",
	protocolText08: "Minutes of suspension of the selection of best offers",
	protocolText09: "Protocol on the tender process",
	protocolText10: "Protocol of the renewal of the tender",
	protocolText11: "Protocol for compliance with formal requirements",
	protocolText12: "Protocol for determining the winner of the tender, point method",
	protocolText13: "Protocol for determining the winner of the selection, lowest price method",
	protocolText14: "Tender Cancellation Protocol",
	protocolText15: "Protocol of evaluation of the qualification part of the tender",
	protocolText16: "Protocol of evaluation of the technical part of the tender",
	protocolText17: "Tender suspension protocol",
	protocolText18: "Protocol for approval of tender documentation",
	modelContracts: "Model contracts",
	typicalProtocols: "Typical protocols",
	fileInstruction: "Instruction files",
	registrationQuestions: "Registration questions",
	scheduleQuestions: "Questions about Schedule",
	scheduleAuctions: "Questions about the Auction",
	scheduleShops: "Questions about the Store",
	selectionQuestions: "Questions about the Selection",
	tenderQuestions: "Questions about the Tender",
	questionsPayment: "Questions about Payment",
	questionsOperator: "Questions about the operator's commission services",
	popularQuestions: "Popular questions",
	electronicDidox: "Electronic document turnover DIDOX",
	integration1C: "Integration with 1C program",
	dibankFeatures: "Dibank's capabilities",
	unitMonitoring: "Monitoring of units",
	otherDocuments: "Other documents",
	select_all: "Select all",
	plan_schedule: "Plan schedule",
	incompleteTransactions: "Incomplete deals",
	end: "-------------------DO NOT PASS THIS LINE--------------------------",

	main_title: "Specialized information portal for public procurement",
	sourceOfFinancing: "Funding source",
	district: "District",
	deliveryConditions: "Delivery conditions",
	customerPhone: "Customer's phone number",
	form_plan_schedule: "Formation of templates of schedule plans",
	unifiedRegisterOfTheContract: "Single Register of Contracts",

	instructionCustomerSelectionTender: "Instruction customer selection of tender",
	instructionSupplierSelectionTender: "Instruction supplier selection of tender",

	instructionCustomerSelectionTenderSize: "477kWt",
	instructionSupplierSelectionTenderSize: "463kWt",

	docTextEthical: "Rules of ethical behavior",
	docTextEthicalSize: "30 kWt",

	infact: "In fact",
	quarterly: "Quarterly",
	exactDate: "Exact date",
	yes_or_no: "Yes/No",
	agree_or_disagree: "Agree/Disagree",
	critical: "Critical",
	preferably: "Preferred",
	systemic: "Systemic",
	expert: "Expert",

	role: "Role",
	clear: "Clear",
	dealDate: "Date of deal",
	selection_prepayment: "Prepayment",
	selection_without_prepayment: "Without prepayment",
	selection_payment_order: "Payment procedure",

	type: "Bidding type",
	selectionQuatity: "Selection",

	// new leng
	directContractsUpperCase: "DIRECT CONTRACTS",
	otherTreatiesUpperCase: "OTHER AGREEMENTS",
	auctionUpperCase: "AUCTION",
	shopUpperCase: "STORE",
	selectionUpperCase: "SELECTION",
	tenderUpperCase: "TENDER",
	nameOne: "Name",
	tnVed: "CNFEA",
	unitMeasurement: "Unit of measure",
	gostStandards: "GOST (ISO) standards",
	startDate: "Start date",
	endDate: "End date",
	importAlternativeRequest: "Request for an import alternative",
	priceFrom: "Price from",
	priceTo: "Price to",

	decreesofTheCabinetOFMinisters: "Decrees of the Cabinet of Ministers",
	docText833:
		"Resolution of the Cabinet of Ministers of the Republic of Uzbekistan “On approval of the order of placement of information and procurement of the Center of Digital Transformation under the Ministry of Investment and Foreign Trade on the portal of electronic cooperation” from 30.09.2019 № PKM 833.",
	docText276:
		"Resolution of the Cabinet of Ministers of the Republic of Uzbekistan “On Approval of the Regulations on the Procedure for Organizing and Conducting Procedures Related to Public Procurement” dated 20.05.2022 No. PKM 276",
	link_1: "Senate of the Oliy Majlis of the Republic of Uzbekistan",
	link_2: "State Customs Committee of the Republic of Uzbekistan",
	link_3: "Ministry of Investment, Industry and Trade of the Republic of Uzbekistan",
	link_4: "Tax Committee under the Ministry of Economy and Finance of the Republic of Uzbekistan",
	link_5: "Legislative Chamber of the Oliy Majlis of the Republic of Uzbekistan",
	link_6: "Ministry of Economy and Finance of the Republic of Uzbekistan",
	link_7: "Agency of Statistics under the President of the Republic of Uzbekistan",
	link_8: "Uzbek Agency for Technical Regulation",

	feedback2: "Feedback",
	supplier_1_title: "Navoi mining metallurgy",
	supplier_2_title: "Uzbekneftegaz",
	supplier_3_title: "Almalyk mining metallurgy",
	supplier_4_title: "AKFA",
	supplier_1_description:
		"Navoi Mining and Metallurgical Combine Joint Stock Company (NMMC JSC) is one of the world's top ten largest gold producers. The Combine is an industrial cluster that has mastered the full production cycle from geological exploration of reserves in the subsoil, ore mining and processing to finished products.",
	supplier_2_description:
		"Uzbekneftegaz is a holding corporation engaged in geological exploration, production, transportation, storage, processing and sale of oil and gas. The largest state-owned company in Uzbekistan.",
	supplier_3_description:
		"JSC “Almalyk MMC” is one of the largest industrial enterprises of Uzbekistan, which for 70-year history has made a worthy contribution to the development of economic potential of our country, which production capacity is based on the reserves of a group of copper-molybdenum, lead-zinc and gold-silver deposits located in the territories of Tashkent, Jizzak, Namangan, Surkhandaryn regions of the Republic of Uzbekistan.",
	supplier_4_description:
		"Over the years, AKFA has established itself as a reliable and responsible manufacturer, in whose products you can be sure.",
	anAuction: "Auction",
	signInAlternativeImport: "Log in to offer!",
	listOfRecommended: "List of recommended documents",
	theVolumeOfIndustrial: "Volume of industrial production",
	proposalForCooperation: "Cooperation proposal",
	inportAndExportVolme: "Import and export volume",
	stateRealEstate: "State-owned real estate",
	budgetPurtchases: "Budget procurement: schedules and plans",
	corporateProcurement: "Corporate procurement: plans and schedules",
	operatorDetailsForNon: "Operator details for extrabudgetary organizations",
	electronicCooperationPortal: "Electronic Cooperation Portal of the Republic of Uzbekistan",
	requisitesForBudgetary: "Requisites for budget organizations",
	specialist: "Special account for payment of commission fee",
	pr: "s/a",
	ls: "S/A",
	street: "street",
	phone: "Phone",
	eMail: "E-mail",
	electronicCooperationPortal3: "Electronic cooperation portal",
	for: "For",
	against: "Opposed",
	onlineExhibitions: "Online exhibitions",
	registerOfManufacturersDealers: "Register of manufacturers/dealers",
	numberOfAddedItems: "Number of products added",
	participantType: "Type of participant",
	dealer: "Dealer",
	manufacturerDealer: "Manufacturer/Dealer",
	functionalCharacteristics: "Functional characteristics",

	sum: "UZS",
	pickup: "Self-delivery",
	specifyQuantity: "Specify the quantity",
	generalCharacteristics: "General characteristics",
	numberOfBidsSubmitted: "Number of submitted offers",
	goodsOfOrganizationsRelatedPersonsWithDisabilities:
		"Goods of organizations belonging to societies of persons with disabilities",
	electricalEquipmentUzeltekhsanoat: "Electrical products (Uzeltekhsanoat)",
	TNVEDCode: "CNFEA code",
	mechanismQuoteRequestAvailableForCustomers: "Price Request Mechanism. Request for Price is available for customers",
	documentsTransferredWithTheGoods: "List of documentation to be transferred with the goods",
	tvedCode: "CNFEA code",
	itemOfExpenses: "Item of expenses",
	binary: "Binary",
	textual: "Textual",
	numeric: "Numerical",
	logIn: "Log in",
	startPrice: "Start amount",
	amountOfDeal: "Contract amount",
	startingPrice: "Starting price",
	cooperation: "Cooperation portal",
	cooperation_child: "In development",
	pay: "Pay up",
	unPrePaid: "Without prepayment",
	prePaid: "With prepayment",
	setPrePaidPercent: "Specify the percentage of prepayment",
	notRequestPrice: "You cannot request a price for your product",
	developing: "In development",
	poweredbyCDT: "Center of Digital Transformation",
	mechanismQuoteRequestNotAvailable: "The price request mechanism is not yet active",
	minFt: "Ministry of Investment, Industry and Trade of the Republic of Uzbekistan",
	limitIsNotChecked: "No limit set",
	contractsCount: "Contracts concluded",
	contractsPrice: "Total amount of deals (mln. UZS)",
	participantsCount: "Participants",
	productsCount: "Goods",
	bannerText1: "Center of Digital Transformation",
	bannerText1Under: "Operator of the electronic public procurement system",
	bannerText2: "Proposals for 100 investment projects",
	bannerText2Under:
		"Within the framework of the “Open Dialogue” of the President of the Republic of Uzbekistan with entrepreneurs",
	yearly: "Per year",
	aboutPortalTitle:
		"The portal is developed in accordance with the Decree of the President of the Republic of Uzbekistan “On measures for further development of industrial cooperation and expansion of production of demanded products” № PP-4302 from 01.05.2019 and the Decree of the Cabinet of Ministers of the Republic of Uzbekistan “On measures to organize the activities of industrial fair and electronic cooperation exchange” № 833 from 30.09.2019.",
	aboutPortalSubtitle:
		"The Electronic Cooperation Portal is a special trading platform created to strengthen and develop cooperation ties in industry between domestic manufacturers, expand the production of industrial products and broadly involve small businesses in industrial activities",
	testingMode: "Website under development",
	instructionForUsers: "Registration",
	instructionForProducts: "Instructions for displaying goods",
	executor: "Executor",
	periodOfExecution: "Deadline",
	protocols: "Protocols",
	dealStatus: "Transaction Status",
	sourceFunded: "Source of funding",
	calculate: "Calculate",
	myFilters: "My filters",
	onlineFair: "Fair",
	authForVote: "Log in to vote",
	timerText: "Trading start time: 03.07.2023 9:00 AM",
	instructionForCert: "Instructions for adding certificates",
	instructionForDiler: "Instructions for adding a dealer",
	day2: "Days",
	day3: "Day",
	day4: "Days",
	certificated: "Certified",
	notCertificated: "Not certified",
	inAYear: "Per year",
	titleOfWithoutPercent: "Standard form of contract (Electronic catalog, full payment)",
	titleOfWithPercent: "Standard form of agreement (Electronic catalog, prepayment)",
	eSignKeyError: "Password entered incorrectly",
	instructionForCellWithoutPrePayment: "Trade process instruction (no prepayment)",
	instructionForRejectingOrEnablingCell: "Instructions on payment and termination of contracts",
	inDevelopingMode: "In development mode",
	abrobation: "The site is at the testing stage",
	questionnaire: "Questionnaire",
	gotoQuestionaree: "Take the questionnaire",
	typeOfPayment: "Type of payment",
	artisans: "Products made by artisans",
	bannerText4:
		"From December 12 to 15, the National Exhibition Complex “Uzexpomarkaz” in the capital is hosting the Republican Interindustry Industrial Fair, the regional stages of which were held in all regions of the country and the Republic of Karakalpakstan during 2023.",
	registerOfActiveTrades: "Register of active trades",
	numberOffer: "Ofer No.",
	maxAmount: "Max. quantity",
	allActiveTrades: "All active trades",
	ourNews: "Advantages and conveniences",
	titleOfFeatutures:
		"Created benefits and conveniences on the Electronic Cooperation Portal of the Digital Transformation Center",
	feature1:
		"Developed the ability to see the <b> winning bidder in the suppliers' personal accounts after the bidding period has expired</b>.",
	feauture2Title: "<b>Inquiries</b> were categorized into:",
	feature2Text1: "all requests;",
	feature2Text2: "my requests (active requests).",
	feauture2FinalText:
		"Also, the task of storing all queries and notifications in the database has been accomplished.",
	feauture3Title: "Developed the ability to view the <b>full history</b> of a trade after it has ended, including:",
	feauture3Text1: "when the request was sent;",
	feauture3Text2: "when the request was accepted;",
	feauture3Text3: "start and end date of bidding;",
	feauture3Text4: "a register of participants who received the bargaining mailing;",
	feauture3Text5: "the price offer of the bidder, as well as the quantity and time;",
	feauture3Text6: "data on the winning bidder.",
	feauture4:
		"The mechanism of <b>forming and maintaining the register of contracts</b> on the Portal has been improved.",
	feauture5:
		"Implemented function for <b>levying a fine</b> or terminating a contract if the customer fails to pay the amount within <b>18 banking business days</b> from the date of the contract formation.",
	feauture6: "Completed  task of categorizing <b>codes requiring a certificate</b> (mandatory / optional).",
	feauture7:
		"The function on <b>SMS notification</b> on the start of bidding, by the first 4 digits of the CNFEA code has been implemented.",
	feauture8:
		"Implemented <b>notification function</b> when dealer and certificate expires, and when imports are detected on an active offer.",
	feauture9: "Developed the ability to see <b>fine detail</b> when terminating a contract.",
	feauture10Title: "<b>Digitized</b> the way in which moderation of offers is carried out, i.e.:",
	feauture10Text1: "all offers are moderated one by one;",
	feauture10Text2:
		"the possibility of continuous monitoring of the queue of own product through your personal account was introduced;",
	feauture10Text3:
		"in cases of moderation of offers without queue, the given offer will not be active at once, the system will automatically put it into active state in its turn.",
	feauture10FinalText:
		"Moderation queue mechanism: if there are other offers in the queue, the offer will not be active immediately after approval, it will be put on hold.",
	feauture11:
		"In order to ensure the transparency of the bidding process, <b>mutual information exchange (integration)</b> was carried out jointly with the Chamber of Accounts, the Committee for the Development of Competition and Consumer Protection and the Antimonopoly Committee.",
	feauture12:
		"The ability to <b>monitor the register of active trades</b> and data on them by Portal users <b>in real time</b> has been implemented",
	readTheFeautures: "I have read it",
	notificationText: "Notification",
	support: "Support",
	code2022: "CNFEA codes (2022)",
	offerUntilActive: "The offer is active until",
	cooperAdress: "100115, Tashkent city, Chilanzar-2, 76, Arnasay str.",
	socialMedia: "Social Networking",
	typeSms: "Enter the code sent to your number",
	send: "Send",
	comment: "Commentary",
	participantAccountInStore: "Customer's savings account number",
	monthCountOfPayment: "Month of payment",
	selectRegion: "Please select a region",
	fullSearchFromList: "Advanced search for reference",
	getInformation: "Get a reference",
	typeFirst3LettersOfProduct: "write the first 3 letters of the product",
	typeFirst3LettersOfCode: "enter the first 4 digits of the CNFEA code",
	pictureOfItem: "Product Image",
	rejectAnswer1: "Absence of goods (work, service)",
	rejectAnswer2: "Insufficient volume of goods (work, service)",
	rejectAnswer3: "Quality, warranty, technical parameters of goods (work, services) do not meet the level of demand",
	rejectAnswer4: "Long (lengthy) delivery time",
	rejectAnswer5: "Other reasons",
	rejectionAnnouncement:
		"Failure to comply with the requirements of mandatory study of the possibility to purchase goods (works, services) from local producers registered on the portal, first of all, entails appropriate responsibility.",
	productNameMin: "The name of the product must consist of at least 3 letters",
	sendedRequestToDMBAt: "Request sent to ISUGF",
	restoringTitle: "ATTENTION, ANNOUNCEMENT!!!",
	restoringText:
		"Dear users of the e-cooperative portal, we would like to notify you about preventive maintenance on our website. Due to these works, access to the site will be temporarily limited. We apologize for any inconvenience and thank you for your understanding.",
	notFound: "Nothing was found for your request",
	loginForInfo: "Log in to get a reference",
	goToPersonalCabinet: "Go to your personal account",
	registerOfSingleBusnises: "Register of single suppliers",
	tableSupllierName: "Name of supplier of goods (goods, services)",
	tableSupllierType: "Name of product (goods, services)",
	statusName: "Status",
	codeOfKlassifikator: "Classifier code",
	dateOfSupplierIn: "Date of entry in the register",
	purpose: "Basis",
	buxoroTopProducts: "Top products of Bukhara region",
	numberOfActiveOffers: "Number of active offers",
	disabilityCompanyText:
		"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0 Dear customer, the offer you have selected concerns the goods of an organization related to societies of persons with disabilities. We inform you that all transactions on these goods will be carried out on the basis of direct contracts.",
	numberOfOffers: "Number of offers",
	active: "Active",
	all: "All",
	registrationDate: "Registration date",
	greenShopping: "Green shopping",
	budget: "Budget",
	typeOrganization: "Type of organization",
	getAllCompanies: "List of registered organizations",
	numberOfOffersByCorp: "Number of manufacturer offers",
	numberOfOffersByDiler: "Number of dealer offers",
	corporative: "Corporate",
	providerOrCustomer: "Organization status",
	customer2: "Customer",
	direct_contract: "Direct contract",
	next: "Next",
	back: "Previous",
	enktCode: "ENKT code",
	epFindings: "Epidemiological findings",
	verOp: "Veterinary reports",
	phyConc: "Phytosanitary conclusions",
	vetSan: "Veterinary and sanitary certificates",
	enAssessment: "Environmental assessment",
	no: "No",
	yes: "Yes",
	thereIs: "There is",
	instructionGovernment: "Instructions for submitting a request to add a product",
	goods: "Goods",
	work_and_services: "Work and services",
	enter_name: "Enter your name",
	phone_number: "Phone number",
	enter_phone_number: "Enter phone number",
	your_email: "Enter your email address",
	registerOfDirectTrades: "Register of direct trades",
	ordinalNumber: "ID",
	organizationNameCustom: "Organization name (customer)",
	orderingTin: "Ordering STIR information",
	companyNameSupplier: "Company name (supplier)",
	suplierTin: "Supplier STIR information",
	addComment: "Add a comment",
	companyComment: "The name of the company that left a comment",
	companyCommentTin: "The TIN information of the company that left a comment",
	comments: "Comments",
	commentAdded: "Comment added",
	errorOccurred: "An error occurred",
	commentRequirements:
		"We remind you!  Comments that humiliate the honor and dignity of a person and disseminate unfounded and false information may be subject to administrative (Article 41 of the Code of the Republic of Uzbekistan on Administrative Liability) and criminal (Article 140 of the Criminal Code of the Republic of Uzbekistan) liability!",
	fullName: "Full name",
	pleaseRegister: "To leave a comment, please register.",
	add: "Add",
	warning: "Warning",
};
