import Vue from "vue";
import VueRouter from "vue-router";
// import i18n from "@/plugins/vue-i18n-config";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		component: () => import("../layouts/MainLayout"),
		children: [
			{
				path: "/",
				name: "home",
				component: () => import("../views/home"),
			},
			{
				path: "/product-list",
				name: "product-list",
				component: () => import("../views/product-list/index.vue"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/searching-offer",
				name: "offers-list",
				component: () => import("../views/offers-list/index.vue"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/product-list/other-products",
				name: "other-products",
				component: () => import("../views/product-list/pages/other-products"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/product-list/category/:categoryId",
				name: "product-list-category",
				component: () => import("../views/product-list/index.vue"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/product-list/region/:regionId",
				// name: "product-list-category",
				component: () => import("../views/product-list/index.vue"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/product-list/:id",
				name: "product-detail",
				component: () => import("../views/product-list/single-product"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/auction",
				name: "auction",
				component: () => import("../views/auction"),
				meta: {
					background: "primary",
				},
			},

			{
				path: "/auction/:id",
				name: "auction-single",
				props: true,
				component: () => import("../views/auction/auction-single"),
			},

			{
				path: "/all-sections",
				name: "all-sections",
				component: () => import("../views/all-sections"),
			},
			{
				path: "/standard-forms",
				name: "standard-forms",
				component: () => import("../views/standard-forms-contracts"),
			},

			{
				path: "/normative-acts",
				name: "normative-acts",
				component: () => import("../views/normative-acts"),
			},

			{
				path: "contracts",
				component: () => import("../views/unified-register-of-contracts"),
				children: [
					{
						path: "",
						name: "ContractIndex",
						component: () => import("../views/unified-register-of-contracts/pages/ContractIndex"),
					},
					{
						path: "details",
						name: "ContractDetails",
						component: () => import("../views/unified-register-of-contracts/pages/Details"),
					},
					{
						path: "selection-contract-details/:id",
						name: "SelectionContractDetails",
						component: () => import("../views/unified-register-of-contracts/pages/SelectionDetails"),
					},
					{
						path: "tender-contract-details",
						name: "TenderContractDetails",
						component: () => import("../views/unified-register-of-contracts/pages/TenderDetails"),
					},
				],
			},
			{
				path: "/plan-graphic",
				name: "plan-graphic",
				component: () => import("../views/plan-graphic"),
			},

			{
				path: "/price-criteria-for-public-procurement",
				name: "price-criteria-for-public-procurement",
				component: () => import("../views/price-criteria-for-public-procurement"),
			},

			{
				path: "/calculator",
				name: "calculator",
				component: () => import("../views/calculator"),
				meta: {
					background: "primary",
				},
			},

			{
				path: "/faq",
				name: "faq",
				component: () => import("../views/faq"),
			},
			{
				path: "/import-alternative-request",
				name: "import-alternative-request",
				component: () => import("../views/import-alternative-request"),
			},

			{
				path: "/news",
				name: "news",
				component: () => import("../views/news"),
			},

			{
				path: "/news/:id",
				name: "SingleNews",
				component: () => import("../views/news/SingleNews"),
			},

			{
				path: "/instructions",
				name: "instructions",
				component: () => import("../views/instructions"),
			},

			{
				path: "/statistics",
				name: "statistics",
				component: () => import("../views/statistics"),
			},

			{
				path: "/tariffs",
				name: "tariffs",
				component: () => import("../views/tariffs"),
			},

			{
				path: "/tariffs-2",
				name: "tariffs-2",
				component: () => import("../views/tariffs-2"),
			},

			{
				path: "/single-supplier-register",
				name: "single-supplier-register",
				component: () => import("../views/single-supplier-register"),
			},

			{
				path: "/corporate-customers-register",
				name: "corporate-customers-register",
				component: () => import("../views/corporate-customers-register"),
			},

			{
				path: "/unscrupulous-executors-register",
				name: "unscrupulous-executors-register",
				component: () => import("../views/unscrupulous-executors-register"),
			},

			{
				path: "/specialized-organizations",
				name: "specialized-organizations",
				component: () => import("../views/specialized-organizations"),
			},

			{
				path: "/budget-customers-register",
				name: "budget-customers-register",
				component: () => import("../views/budget-customers-register"),
			},

			{
				path: "direct-purchases",
				component: () => import("../views/direct-purchases"),
				children: [
					{
						path: "",
						name: "purchase-index",
						component: () => import("../views/direct-purchases/pages"),
					},
					{
						path: "details/:id",
						name: "purchase-details",
						component: () => import("../views/direct-purchases/pages/details"),
					},
				],
			},

			{
				path: "selection",
				component: () => import("../views/selection"),
				children: [
					{
						path: "",
						name: "selection-index",
						component: () => import("../views/selection/pages"),
					},
					{
						path: "selection/:id",
						name: "selection-details",
						component: () => import("../views/selection/pages/details"),
					},
					{
						path: "details/:id",
						name: "SelectionDetails",
						component: () => import("../views/selection/pages/SelectionItemDetails"),
					},
				],
			},
			{
				path: "tender",
				component: () => import("../views/tender"),
				children: [
					{
						path: "",
						name: "tender-index",
						component: () => import("../views/selection/pages"),
					},
					{
						path: "tender/:id",
						name: "tender-details",
						component: () => import("../views/selection/pages/details"),
					},
					{
						path: "details/:id",
						name: "TenderDetails",
						component: () => import("../views/selection/pages/SelectionItemDetails"),
					},
				],
			},
			{
				path: "/alternative-request",
				name: "alternative-request",
				component: () => import("../views/import-alternative-request"),
			},
			{
				path: "/contacts",
				name: "contacts",
				component: () => import("../views/contacts"),
			},
			{
				path: "/import-and-export",
				name: "import-and-export",
				component: () => import("../views/import-and-export"),
			},
			{
				path: "/suppliers",
				name: "suppliers",
				component: () => import("../views/suppliers/Index"),
				meta: {
					background: "primary",
				},
			},
			{
				path: "/online-exhibitions",
				name: "online-suppliers",
				component: () => import("../views/online-exhibitions/index"),
			},
			{
				path: "/single-supplier",
				name: "single-suppliers",
				component: () => import("../views/single-suplier/index.vue"),
			},
			{
				path: "/all-companies",
				name: "all-companies",
				component: () => import("../views/all-companies/index.vue"),
			},
			{
				path: "/about",
				name: "about",
				component: () => import("../views/about/index.vue"),
			},
			{
				path: "/public-offer",
				name: "public-offer",
				component: () => import("../views/public-offer/index.vue"),
			},
			{
				path: "/volumes",
				name: "volumes",
				component: () => import("../views/volumes/index.vue"),
			},
			{
				path: "/active-trades",
				name: "active-trades",
				component: () => import("../views/activeTrades/index.vue"),
			},
			{
				path: "/active-innovation-disability-trades",
				name: "active-innovation-disability-trades",
				component: () => import("../views/activeTrades/Innovation-Disability.vue"),
			},
		],
	},
];

const router = new VueRouter({
	// mode: "history",
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	base: process.env.BASE_URL,
	routes,
});

export default router;

